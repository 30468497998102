var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_vm._m(1),_c('div',{style:({
      backgroundImage: `url(${_vm.bgSkill})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    })},[_vm._m(2),_c('div',{staticClass:"pt-lg-5"},[_c('div',{},[_c('div',{staticClass:"section-skill"},[_c('div',{staticClass:"section-gp"},[_c('div',{staticClass:"mt-lg-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 mb-4"},[_c('router-link',{attrs:{"to":"/projects"}},[_c('img',{attrs:{"src":require("@/assets/image/1.png"),"alt":"Graphic 1","width":"100%"}})])],1),_c('div',{staticClass:"col-12 col-md-6 mb-4"},[_c('router-link',{attrs:{"to":"/projects/f&b"}},[_c('img',{attrs:{"src":require("@/assets/image/2.png"),"alt":"Graphic 1","width":"100%"}})])],1),_c('div',{staticClass:"col-12 col-md-6 mb-4"},[_c('router-link',{attrs:{"to":"/projects/beauty"}},[_c('img',{attrs:{"src":require("@/assets/image/3.png"),"alt":"Graphic 1","width":"100%"}})])],1),_c('div',{staticClass:"col-12 col-md-6 mb-4"},[_c('router-link',{attrs:{"to":"/projects"}},[_c('img',{attrs:{"src":require("@/assets/image/4.png"),"alt":"Graphic 1","width":"100%"}})])],1)])])]),_c('div',{staticClass:"section-ai"},[_c('div',{},[_c('slick-slider',{attrs:{"options":_vm.optionsGP}},[_c('div',[_c('a',{staticClass:"d-block",attrs:{"href":"https://www.instagram.com/thebeige.studio/","target":"_blank"}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/image/5.png"),"alt":"Image AI"}})])]),_c('div',[_c('a',{staticClass:"d-block",attrs:{"href":"https://www.instagram.com/thebeige.studio/","target":"_blank"}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/image/6.png"),"alt":"Image AI"}})])]),_c('div',[_c('a',{staticClass:"d-block",attrs:{"href":"https://www.instagram.com/thebeige.studio/","target":"_blank"}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/image/7.png"),"alt":"Image AI"}})])])])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-none d-lg-block",staticStyle:{"background-color":"#062648"}},[_c('div',{staticClass:"banner p-0"},[_c('img',{staticStyle:{"border-radius":"0px"},attrs:{"src":require("@/assets/image/banner-home.png"),"alt":"linhchi-home"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-block d-lg-none mt-lg-4 banner-mobile",staticStyle:{"background-color":"#062648"}},[_c('div',{staticClass:"banner p-0"},[_c('img',{staticStyle:{"border-radius":"0px"},attrs:{"src":require("@/assets/image/slider-logo-3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4"},[_c('div',{staticClass:"container pt-5"},[_c('div',{staticClass:"row about-wrap"},[_c('div',{staticClass:"co-12 col-lg-5 image-about"},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/image/cv2.png"),"alt":""}})]),_c('div',{staticClass:"col-12 col-lg-7 about-me"},[_c('div',{staticClass:"h-100 d-flex flex-column align-items-center justify-content-center"},[_c('h2',{staticClass:"text-header text-center text-justify"},[_vm._v("About me")]),_c('p',{staticClass:"about-text"},[_vm._v(" Hello! I’m a creative and detail-oriented graphic designer with many experience in marketing agencies. I have a proven track record of managing graphic design projects from inception to execution, ensuring that each piece not only meets client expectations but also resonates with target audiences. ")]),_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col-6 col-lg-3 mb-3 px-2"},[_c('div',{staticClass:"about-card"},[_c('div',{staticClass:"about-card--title d-flex align-items-center"},[_c('h2',[_vm._v("5")])]),_c('p',[_vm._v("years of experience")])])]),_c('div',{staticClass:"col-6 col-lg-3 mb-3 px-2"},[_c('div',{staticClass:"about-card"},[_c('div',{staticClass:"about-card--title d-flex align-items-center"},[_c('i',{staticClass:"fa-solid fa-chevron-right"}),_c('h2',[_vm._v("40")])]),_c('p',[_vm._v("brands")])])]),_c('div',{staticClass:"col-6 col-lg-3 mb-3 px-2"},[_c('div',{staticClass:"about-card"},[_c('div',{staticClass:"about-card--title d-flex align-items-center"},[_c('i',{staticClass:"fa-solid fa-chevron-right"}),_c('h2',[_vm._v("300")])]),_c('p',[_vm._v("projects")])])]),_c('div',{staticClass:"col-6 col-lg-3 mb-3 px-2"},[_c('div',{staticClass:"about-card"},[_c('div',{staticClass:"about-card--title d-flex align-items-center"},[_c('i',{staticClass:"fa-solid fa-chevron-right"}),_c('h2',[_vm._v("10")])]),_c('p',[_vm._v("industries & sectors")])])])])])])])])])
}]

export { render, staticRenderFns }